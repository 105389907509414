:root {
  --brand-purple: #663399;
  --brand-blue: #4775FF;
  --brand-error: red; }

.m-none {
  margin: 0; }

.p-none {
  padding: 0; }

.m-t-none {
  margin-top: 0; }

.p-t-none {
  padding-top: 0; }

.m-r-none {
  margin-right: 0; }

.p-r-none {
  padding-right: 0; }

.m-b-none {
  margin-bottom: 0; }

.p-b-none {
  padding-bottom: 0; }

.m-l-none {
  margin-left: 0; }

.p-l-none {
  padding-left: 0; }

.m-xxs {
  margin: 0.125rem; }

.p-xxs {
  padding: 0.125rem; }

.m-t-xxs {
  margin-top: 0.125rem; }

.p-t-xxs {
  padding-top: 0.125rem; }

.m-r-xxs {
  margin-right: 0.125rem; }

.p-r-xxs {
  padding-right: 0.125rem; }

.m-b-xxs {
  margin-bottom: 0.125rem; }

.p-b-xxs {
  padding-bottom: 0.125rem; }

.m-l-xxs {
  margin-left: 0.125rem; }

.p-l-xxs {
  padding-left: 0.125rem; }

.m-xs {
  margin: 0.25rem; }

.p-xs {
  padding: 0.25rem; }

.m-t-xs {
  margin-top: 0.25rem; }

.p-t-xs {
  padding-top: 0.25rem; }

.m-r-xs {
  margin-right: 0.25rem; }

.p-r-xs {
  padding-right: 0.25rem; }

.m-b-xs {
  margin-bottom: 0.25rem; }

.p-b-xs {
  padding-bottom: 0.25rem; }

.m-l-xs {
  margin-left: 0.25rem; }

.p-l-xs {
  padding-left: 0.25rem; }

.m-sm {
  margin: 0.5rem; }

.p-sm {
  padding: 0.5rem; }

.m-t-sm {
  margin-top: 0.5rem; }

.p-t-sm {
  padding-top: 0.5rem; }

.m-r-sm {
  margin-right: 0.5rem; }

.p-r-sm {
  padding-right: 0.5rem; }

.m-b-sm {
  margin-bottom: 0.5rem; }

.p-b-sm {
  padding-bottom: 0.5rem; }

.m-l-sm {
  margin-left: 0.5rem; }

.p-l-sm {
  padding-left: 0.5rem; }

.m-md {
  margin: 1rem; }

.p-md {
  padding: 1rem; }

.m-t-md {
  margin-top: 1rem; }

.p-t-md {
  padding-top: 1rem; }

.m-r-md {
  margin-right: 1rem; }

.p-r-md {
  padding-right: 1rem; }

.m-b-md {
  margin-bottom: 1rem; }

.p-b-md {
  padding-bottom: 1rem; }

.m-l-md {
  margin-left: 1rem; }

.p-l-md {
  padding-left: 1rem; }

.m-lg {
  margin: 2rem; }

.p-lg {
  padding: 2rem; }

.m-t-lg {
  margin-top: 2rem; }

.p-t-lg {
  padding-top: 2rem; }

.m-r-lg {
  margin-right: 2rem; }

.p-r-lg {
  padding-right: 2rem; }

.m-b-lg {
  margin-bottom: 2rem; }

.p-b-lg {
  padding-bottom: 2rem; }

.m-l-lg {
  margin-left: 2rem; }

.p-l-lg {
  padding-left: 2rem; }

.m-xl {
  margin: 4rem; }

.p-xl {
  padding: 4rem; }

.m-t-xl {
  margin-top: 4rem; }

.p-t-xl {
  padding-top: 4rem; }

.m-r-xl {
  margin-right: 4rem; }

.p-r-xl {
  padding-right: 4rem; }

.m-b-xl {
  margin-bottom: 4rem; }

.p-b-xl {
  padding-bottom: 4rem; }

.m-l-xl {
  margin-left: 4rem; }

.p-l-xl {
  padding-left: 4rem; }

.m-xxl {
  margin: 8rem; }

.p-xxl {
  padding: 8rem; }

.m-t-xxl {
  margin-top: 8rem; }

.p-t-xxl {
  padding-top: 8rem; }

.m-r-xxl {
  margin-right: 8rem; }

.p-r-xxl {
  padding-right: 8rem; }

.m-b-xxl {
  margin-bottom: 8rem; }

.p-b-xxl {
  padding-bottom: 8rem; }

.m-l-xxl {
  margin-left: 8rem; }

.p-l-xxl {
  padding-left: 8rem; }

.ant-btn:not(.ant-btn-link):hover {
  color: white;
  border: 1px solid var(--brand-blue); }

.ant-btn-primary {
  background-color: var(--brand-blue);
  border: var(--brand-blue); }
  .ant-btn-primary:hover {
    background-color: var(--brand-blue);
    border: var(--brand-blue);
    color: white;
    opacity: 0.8; }

.ant-btn-danger:hover {
  border: none;
  color: white; }

.ant-radio-button-wrapper:hover {
  color: var(--brand-blue); }

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: var(--brand-blue); }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: var(--brand-blue);
    opacity: 0.8; }

.input-field {
  max-width: 500px; }

.ant-alert-with-description {
  border-radius: 0; }

.ant-alert-with-description .ant-alert-close-icon {
  top: 7px;
  right: 5px; }

.popup__wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.5em;
  margin-top: 10px; }

/* ...::: TRELLO RESET :::...
 * Resets the Trello Styles so that they don't interfere with ant design. 
 */
.ant-table-wrapper table, td, th {
  border: inherit;
  border-collapse: inherit;
  border-spacing: 0;
  border-color: inherit;
  vertical-align: inherit; }

.ant-table-wrapper table {
  margin-bottom: inherit; }

.ant-table-wrapper thead {
  background: inherit; }

.ant-calendar-picker input:not([type=file]) {
  background-color: inherit;
  box-shadow: inherit;
  display: inherit;
  margin-bottom: inherit; }

.ant-calendar-panel input:not([type=file]) {
  background-color: inherit;
  box-shadow: inherit;
  display: inherit;
  margin-bottom: inherit; }

input:not([type="file"]).ant-input-number-input {
  background-color: inherit;
  box-shadow: inherit;
  display: inherit;
  margin-bottom: inherit; }

input.ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0; }

.ant-calendar-picker-input.ant-input {
  border: 1px solid #d9d9d9; }

#purchaseForm input[type=checkbox], form input[type=radio] {
  width: 24px;
  height: 24px; }
